import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const LegalDisclaimerPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Disclaimer for PJK marketing ltd</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              If you require any more information or have any questions about
              our site's disclaimer, please feel free to contact us by email at
              info@ai-productreviews.com. Our Disclaimer was generated with the
              help of the{" "}
              <a href="https://www.disclaimergenerator.net/">
                Free Disclaimer Generator
              </a>
              .
            </p>

            <h2>Disclaimers for Ai Product reviews</h2>

            <p>
              All the information on this website -
              https://ai-productreviews.com/ - is published in good faith and
              for general information purpose only. Ai Product reviews does not
              make any warranties about the completeness, reliability and
              accuracy of this information. Any action you take upon the
              information you find on this website (Ai Product reviews), is
              strictly at your own risk. Ai Product reviews will not be liable
              for any losses and/or damages in connection with the use of our
              website.
            </p>

            <p>
              From our website, you can visit other websites by following
              hyperlinks to such external sites. While we strive to provide only
              quality links to useful and ethical websites, we have no control
              over the content and nature of these sites. These links to other
              websites do not imply a recommendation for all the content found
              on these sites. Site owners and content may change without notice
              and may occur before we have the opportunity to remove a link
              which may have gone 'bad'.
            </p>

            <p>
              Please be also aware that when you leave our website, other sites
              may have different privacy policies and terms which are beyond our
              control. Please be sure to check the Privacy Policies of these
              sites as well as their "Terms of Service" before engaging in any
              business or uploading any information.
            </p>

            <h2>Consent</h2>

            <p>
              By using our website, you hereby consent to our disclaimer and
              agree to its terms.
            </p>

            <h2>Update</h2>

            <p>
              Should we update, amend or make any changes to this document,
              those changes will be prominently posted here.
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default LegalDisclaimerPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Legal Disclaimer | AI Product Reviews"}
      description={
        "Understand the limitations and responsibilities associated with our AI product reviews through our legal disclaimer."
      }
      pathname={location.pathname}
    />
  );
};
